@mixin bullet-wrap() {
  display: inline-block;
  content: '';
  -webkit-border-radius: 0.375rem;
  border-radius: 0.375rem;
  height: 8px;
  width: 8px;
  margin-right: 0.3rem;
}
.store-card-container {
  position: relative;
  padding: 3% 5%;
  margin: 1.8% 0 0 1.8%;
  transition: 0.4s all;
  background: #fff;
  border: 1px solid #e3e3e3;
  box-sizing: border-box;
  border-radius: 8px;

  & .store-card-storename-dist-container {
    display: flex !important;
    justify-content: space-between;
    & .store-card-storename-dist-section {
      display: flex;
      & .store-card-storename {
        display: inline;
        &.store-card-name {
          font-family: $National2Medium;
          font-style: normal;
          font-weight: 500 !important;
          font-size: 18px;
          line-height: 30px;
          color: $Brand-Black;
          @media #{$breakpoint-below-tablet} {
            max-width: 260px;
            overflow: hidden;
            white-space: nowrap;
            margin: auto 0;
            text-overflow: ellipsis;
            @media (orientation: landscape) {
              max-width: 540px;
            }
          }
          @media #{$mobile-only} {
            max-width: 240px;
          }
        }
      }
      & .store-distance {
        margin-left: 5px;
        margin-top: 7px;
        font-family: $National2Regular;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        color: $Dark-Gray;
      }
    }
    & .store-index {
      display: none;
      @media #{$breakpoint-below-tablet} {
        display: inline;
        font-size: 12px;
        margin-top: 8px;
        font-weight: 500;
      }
    }
  }

  & .store-card-content-container {
    margin: 0;
    padding-top: 0;
    display: flex;
    flex-flow: column wrap;
    align-items: flex-start;

    & .store-card-ordertypes {
      display: inline-flex;
      flex-wrap: wrap;
      grid-gap: 1%;
      gap: 1%;
      width: 100%;

      & .store-card-order-option {
        font-family: $National2Medium;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 22px;
        color: $Dark-Gray;
        text-transform: capitalize;
        margin-right: 9px;

        & .tick-image {
          height: 10px;
          width: 12px;
          margin-right: 5px;
        }
      }
    }

    & .store-card-store-address {
      width: 100% !important;

      & .store-address {
        font-family: $National2Regular;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: $Dark-Gray;
        margin-right: 8px;
      }

      & .map-link {
        color: $Brand-Black !important;
        font-weight: 700;

        & .card-map-link {
          color: $Brand-Black !important;
          font-weight: 500 !important;
          font-family: $National2Medium;
          font-size: 14px !important;
          line-height: 24px !important;
          text-decoration: underline;
          -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none;
        }
      }
    }

    & .kfcdirectionLink{
      @media #{$breakpoint-above-destop-large-desktop} {
        cursor: pointer;
        display: inline-block;
      }
    }

    & .kfcdirection{
      font-family: $National2Regular;
      font-style: normal;
      // font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      text-decoration-line: underline;
      font-weight: bold;
      color: #202124;
      margin-top: 8px;
      display: block;
      span{
        margin-left: 5px;
      }
    }

    & .mt15{
      margin-top: 15px;
    }

    & .contact-icon{
      width: 19px;
      height: 19px;
      position: relative;
      left: -2px;
    }

    & .contact-info{
      font-family: $National2Regular;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 24px;
      text-decoration-line: underline;
      color: #202124;
      position: relative;
      top: -2px;
    }

    .open-text:before{
      background: #008300;
      @include bullet-wrap;
    }
    .closed-text:before{
      background: #FF2E00;
      @include bullet-wrap;
    }
    .until-text:before{
      content: "\2022";
    }
    .special-hrs{
      color: green !important;
    }
    .hours-view{
      width: 100%;
      .dispotion-hours-text{
        font-family: $National2Regular;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 24px;
        color: #202124;
        margin: 12px 0 7px 0;
      }
      .hours-text{
        width: 100%;
        font-family: $National2Regular;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 25px;
        display: flex;
        align-items: center;
        letter-spacing: -0.1px;
        color: #000000;
      }
      .day-details{
        width: 50%;
      }
      .special-day{color: $step-completed-color;}
      .time-details{
        width: 50%;
        text-align: right;
      }
    }

    & .hoursHidden{
      display:none
    }

    & .store-timing-msg {
      font-family: $National2Medium;
      font-size: 14px !important;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      display: flex;
      margin-top: 10px;
      &.hoursHiddenArrow .caretIcon img {
        transform: rotate(180deg);
      }

      &.info-timingClosed,
      & .closed-text {
        color: $Red-Orange !important;
        font-family: $National2Medium;
        font-weight: 500 !important;

        & ul {
          display: flex;
          font-size: 14px;
          margin-bottom: 0;
          padding-left: 24px;

          & :first-child {
            list-style: none;
            position: relative;
            right: 12px;

            & .closed-text {
              text-transform: capitalize;
            }
          }
        }
        & .list-circle {
          display: list-item;
          color: #ff2e00;
          margin-left: 23px;
          left: 19px;
        }
      }
    }

    & .facilities {
      width: 100%;
      margin: 20px 0;

      & .facility-img {
        margin-bottom: 5px;
        margin-right: 1%;
      }
    }
  }

  & .search-store-card-button {
    display: flex;
    @media #{$mobile-only}{
      display: flex;
      flex-wrap: wrap;
    }
      & .favorite-img{
        cursor: pointer;
          padding-right: 16.8px;
      }
    & .search-store-card-btn{
      width: 244px;
    }
    /*
    & .search-start-order-btn{
      padding: 11px 30px;
      width: 244px;
    }
    */
    & .redButton {
      margin-left: 5px;
    }
    & button.button-contained {
      margin-right: 5px;
      @media #{$breakpoint-above-medium-desktop}{
      width: 223px;
      }
      @media #{$breakpoint-below-tablet} {
        margin-right: 5px;
      }
      @media #{$mobile-only}{
        width: 100%;
        margin: 10px 0;
      }
  }
  
  }
}

.delivery-module-form.search-store-global {
  margin: 0;
  & .search-store-global-content {
    margin: 0 15%;
    @media #{$breakpoint-below-tablet} {
      margin: 0 5%;
    }
  }
  .placesautocomplete-border {
    border-top: 1px solid $Light-Gray;
    margin: 5% 0;
  }
  



  & .kfc-near-you-section{
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    padding-left: 11px;
    padding-right: 13px;

    @media #{$breakpoint-below-tablet} {
      padding-left: 10px;
      padding-right: 14px; 
    }

    & .kfc-count{
      font-family: $National2Medium;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      color: $Brand-Black;
    }

    & .list-map-link-section{
        margin-left: auto;
      & .list-map-link{
        font-family: $National2Medium;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        color: $Brand-Black;
        text-decoration: underline;
        text-decoration-skip-ink: none;
        text-transform: capitalize;
  
        & .map-img{
          margin-right: 5px;
        }
      }
    }
  }

  & .login-section {
    width: 100%;
    & .hide-location-link {
      display: none;
    }
    & .disable-location-link{
        cursor: not-allowed;
        opacity: 0.7;
        & a {
          pointer-events: none;
          text-decoration: none;
        }
      }
  }

  & .global-map {
    & .disable-location-icon{
      opacity: 0.7;
          cursor: not-allowed;
        & img {
          pointer-events: none;
        }
      }
  }

  & .search-delivery-address {
    margin-right: 0px;
  }

  & .delivery-address-section {
    & .input-delivery-address input {
      padding-left: 2.7rem;

    }
  }

  & .err-container{
    position: absolute;
    width: 69%;
    z-index: 2;
    margin: 1% 0.7%;
    padding-bottom: 2%;

    @media #{$breakpoint-below-tablet} {
      width: 89%!important;
      margin: 1.5% 0.5%;
    }

    .closed-store{
      padding: 11px 32px!important;
      border: 1px solid $Brand-Black;
    }
  }
  & .inline-error{
    & .err-container{
      position: absolute;
      width: 69%;
    }
  }
  & .alert-location-popup {
    margin: 3% 0;
    position: relative;
    width: 100%;
    top: 0;
    height: unset;
    padding: 5vh 0;

    & .location-alert-icon{
      margin-top: 0;
    }
  }
  & .location-error-message {
    padding: 0 15%;
    text-align: center;
  }

  & .unit-number-container {
    padding: 5% 0 0;
    height: auto;

    & .icon-with-address {
      padding: 0 15%;
    }
  }
  & .unit-number-input-div {
    position: relative;
    margin: 30px 0 0;
    width: 80%;

    & input {
      width: 100%;
      display: inline-block;
      padding: 0 0 5px 0;
      background-color: transparent;
      border: none;
      border-bottom: solid 1.5px $Brand-Black;
      outline: none;
      z-index: 20;
      font-weight: 500;
      font-family: $National2Regular;
      font-style: normal;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: -0.1px;
      color: $Brand-Black;
    }

    & label {
      display: inline-block;
      transition: all 0.2s ease;
      z-index: -1;
      position: relative;
      top: -30px;
      font-weight: 400;
      font-family: $National2Regular;
      font-style: normal;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: -0.1px;
      color: $Brand-Black;
    }
    & input[type="text"]:focus ~ label,
    input[type="text"]:valid ~ label {
      top: -50px;
      font-size: 12px;
      line-height: 22px;
      color: $Dark-Gray;
    }
  }

  @include inline-error-mixin;
}

.global-map {
  & .store-card-container {
    position: absolute;
    width: 35%;
    z-index: 1;
    &.map-card {
      padding: 1.5%;
      width: fit-content;
      min-height: 235px;
      @media #{$breakpoint-below-desktop-above-tablet} {
        width: 45%;
        max-width: unset;
      }
      
      @media #{$breakpoint-below-tablet} {
        max-width: unset;
        padding: 5% 4%;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        margin: 0%;

        @media (orientation: landscape) {
          padding: 1.5%;
          width: 40%;
          bottom: -40%;
        }
      }
      & .store-index {
        margin: auto 0;
        @media #{$breakpoint-below-tablet} {
          @media (orientation: landscape) {
            display: none;
          }
        }
      }
      & .map-view {
        display: flex;
        & .store-card-name {
            max-width: 240px;
            overflow: hidden;
            white-space: nowrap;
            margin: auto;
            text-overflow: ellipsis;
        
            @media #{$breakpoint-below-tablet} {
              max-width: 260px;
            }
        
            @media #{$mobile-only} {
              max-width: 245px;
            }
        }
        & .store-distance {
          margin: 8px 0 auto 5px;
        }
      }
    }
  }
  & .use-location-icon{
    margin: auto;
    position: absolute;
    z-index: 1;
    padding-right: 1%;
    right: 0px;
    cursor: pointer;
    @media #{$breakpoint-above-tablet} {
      bottom: 125px!important;
    }
    @media #{$breakpoint-below-tablet} {
      display: block;
      width: 100vw;
      padding-left: 2%;
      @media (orientation: landscape) {
        position: relative;
        margin-top: -5%;
      }
     }
    
  }
}

#google-map{
  width: 100%;
  // height: calc(100vh - 189px - 167px)!important;
  // @media #{$breakpoint-below-tablet} {
  //   height: calc(100vh - 189px - 240px)!important;
  //   @media (orientation: landscape) {
  //     height: calc(100vh - 189px)!important;
  //   }
  // }
}